import { useEffect, useState } from "react";
import GlobalSVG from '../../utils/GlobalSVG';
import moment from 'moment';
import MaintenanceLogLine from "../MaintenanceLogLine";

const MaintenanceLogsLines = (props) => {

    const [sorting, setSorting] = useState({ lastActionDate: true })
    const [logs, setLogs] = useState(null)
    const [adding, setAdding] = useState(null)
    const [equipmentList, setEquipmentList] = useState(null)
    const [groupsList, setGroupsList] = useState(null)

    useEffect(() => {
        if (!props.group) return;
        let tempGroup = JSON.parse(JSON.stringify(props.group))
        let seen = new Set()
        let finalDict = {}
        tempGroup.activities.forEach(e => {
            if (seen.has(e.channel_name + e.id)) return
            seen.add(e.channel_name + e.id)
            finalDict[e.channel_name] = { id: e.id, threshold: e.threshold, tolerance: e.tolerance, timebase: e.timebase }
        })
        setEquipmentList(finalDict)
    }, [props.group, props.groups])

    useEffect(() => {
        if (!props.byCircuit || !props.groups) return;
        let tempGroups = JSON.parse(JSON.stringify(props.groups))
        let seen = new Set()
        let finalDict = {}
        tempGroups.forEach(e => {
            e.activities.forEach(a => {
                if (seen.has(e.type + a.id)) return
                seen.add(e.type + a.id)
                finalDict[e.type] = { id: a.id, threshold: a.threshold, tolerance: a.tolerance, group_id: a.maintenance_id, timebase: a.timebase }
            })
        })
        setGroupsList(finalDict)
    }, [props.byCircuit, props.groups])

    useEffect(() => {
        if (!props.groups || (!props.group && !props.byCircuit)) return
        let tempGroup = { logs: [] }
        if (props.byCircuit) {
            props.groups.forEach(group => {
                tempGroup.logs.push(...JSON.parse(JSON.stringify(group.logs)))
            })
        } else {
            tempGroup = JSON.parse(JSON.stringify(props.group))
        }
        for (let key in tempGroup) {
            if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                tempGroup[key] = Math.round(tempGroup[key])
            }
        }

        for (let field in sorting) {
            tempGroup.logs.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null) return sorting[field] ? 1 : -1;
                if (b[field] === null) return sorting[field] ? -1 : 1;

                switch (field) {
                    case 'completed_date':
                    case 'updated_at':
                        return sorting[field]
                            ? moment(a[field]).diff(moment(b[field]))
                            : moment(b[field]).diff(moment(a[field]));
                    case 'channel_name':
                    case 'performed_by':
                    case 'updated_by':
                        return sorting[field]
                            ? a[field].localeCompare(b[field])
                            : b[field].localeCompare(a[field]);
                    default:
                        return sorting[field]
                            ? a[field] - b[field]
                            : b[field] - a[field];
                }
            });
        }

        setLogs(tempGroup.logs)

    }, [props.groups, props.group, props.byCircuit, sorting])

    const handleSort = (field) => {
        setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    return (
        <div>
            <div className="flex flex-nowrap justify-between items-center mt-[30px] mb-[10px]">
                <h2 className="text-xl font-semibold">Maintenance Logs</h2>
                <div className="flex flex-nowrap justify-end items-center">
                    {GlobalSVG.plus2()}
                    <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setAdding(true)}>Add Log</p>
                </div>
            </div>
            <div className="w-full overflow-auto">
                <div className="min-w-[1500px]">
                    <div className='overflow-auto'>
                        <div className='flex flex-nowrap justify-between py-4 my-2 w-full'>
                            <div className='flex flex-nowrap justify-start items-center w-[82px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('completed_date')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("completed_date") ? 'text-[green]' : ''}`}>Completed</span>
                                {sorting.hasOwnProperty("completed_date") && sorting.completed_date === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("completed_date") && sorting.completed_date === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            {!props.byCircuit && <div className='flex flex-nowrap justify-start items-center w-[150px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('channel_name')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("channel_name") ? 'text-[green]' : ''}`}>Equipment</span>
                                {sorting.hasOwnProperty("channel_name") && sorting.channel_name === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("channel_name") && sorting.channel_name === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>}
                            {props.byCircuit && <div className='flex flex-nowrap justify-start items-center w-[100px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('type')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("type") ? 'text-[green]' : ''}`}>Activity</span>
                                {sorting.hasOwnProperty("type") && sorting.type === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("type") && sorting.type === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>}
                            <div className='flex flex-nowrap justify-start items-center w-[60px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('threshold')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("threshold") ? 'text-[green]' : ''}`}>Threshold</span>
                                {sorting.hasOwnProperty("threshold") && sorting.threshold === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("threshold") && sorting.threshold === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[60px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('tolerance')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("tolerance") ? 'text-[green]' : ''}`}>Tolerance</span>
                                {sorting.hasOwnProperty("tolerance") && sorting.tolerance === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("tolerance") && sorting.tolerance === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[130px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('runtime')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("runtime") ? 'text-[green]' : ''}`}>Runtime Since Maint.</span>
                                {sorting.hasOwnProperty("runtime") && sorting.runtime === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("runtime") && sorting.runtime === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[70px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('daysOverdue')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("daysOverdue") ? 'text-[green]' : ''}`}>Overdue</span>
                                {sorting.hasOwnProperty("daysOverdue") && sorting.daysOverdue === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("daysOverdue") && sorting.daysOverdue === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[110px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('percent')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("percent") ? 'text-[green]' : ''}`}>Threshold Used</span>
                                {sorting.hasOwnProperty("percent") && sorting.percent === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("percent") && sorting.percent === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[100px] text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('performed_by')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("performed_by") ? 'text-[green]' : ''}`}>Performed By</span>
                                {sorting.hasOwnProperty("performed_by") && sorting.performed_by === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("performed_by") && sorting.performed_by === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-1/12 text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('updated_at')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("updated_at") ? 'text-[green]' : ''}`}>Updated On</span>
                                {sorting.hasOwnProperty("updated_at") && sorting.updated_at === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("updated_at") && sorting.updated_at === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-1/12 text-[11px] text-[#B8BBB9] font-semibold cursor-pointer'
                                onClick={() => handleSort('updated_by')}>
                                <span className={`mr-2 ${sorting.hasOwnProperty("updated_by") ? 'text-[green]' : ''}`}>Updated By</span>
                                {sorting.hasOwnProperty("updated_by") && sorting.updated_by === true ?
                                    GlobalSVG.sortArrowAsc('green') :
                                    sorting.hasOwnProperty("updated_by") && sorting.updated_by === false ?
                                        GlobalSVG.sortArrowDesc('green') :
                                        GlobalSVG.sortArrowAsc('#B8BBB9')}
                            </div>
                            <div className='flex flex-nowrap justify-start w-[75px]'></div>
                        </div>
                        {
                            adding &&
                            <MaintenanceLogLine log={props.byCircuit ? { channel_name: props.channelName } : {}} adding={adding} setAdding={setAdding} equipmentList={equipmentList} groupsList={groupsList} group={props.group} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                        }
                        {
                            logs && logs.map((e, ix) => (
                                <MaintenanceLogLine key={ix + " " + e.id} log={e} equipmentList={equipmentList} groupsList={groupsList} group={props.group} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaintenanceLogsLines;