import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import { Link, useLocation } from 'react-router-dom';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import ConservationActivities from '../../components/ConservationActivities';
import Loader from "../../components/Loader";
import LinksModal from '../../components/LinksModal';
import DetailsModal from '../../components/DetailsModal';
import GlobalFuncs from '../../utils/GlobalFuncs';
import Tooltip from '../../components/ToolTip';
import Accordion from "../../components/Accordion";

const Conservation = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [showLinks, setShowLinks] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [activities, setActivities] = useState([])
    const [excelList, setExcelList] = useState(null)
    const [filteredActivities, setFilteredActivities] = useState([])
    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState({ startDate: true })
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext);
    const [activitySummary, setActivitySummary] = useState({
        'Implemented': 0,
        'In Progress': 0,
        'Investigating': 0,
        'On Hold': 0,
        'Not Implemented': 0,
        'Total': 0,
        kwhToDate: 0,
        kwhPriceToDate: 0,
        kwhYear: 0,
        kwhPriceYear: 0,
        kvaToDate: 0,
        kvaPriceToDate: 0,
        kvaYear: 0,
        kvaPriceYear: 0,
        gasToDate: 0,
        gasPriceToDate: 0,
        gasYear: 0,
        gasPriceYear: 0,
        waterToDate: 0,
        waterPriceToDate: 0,
        waterYear: 0,
        waterPriceYear: 0,
        coToDate: 0,
        coPriceToDate: 0,
        coYear: 0,
        coPriceYear: 0,
    })
    const [showBuildings, setShowBuildings] = useState(false)

    useEffect(() => {
        if (queryParams.get('building') === 'all') return
        context.setBuilding(queryParams.get('building'))
        setShowBuildings(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])


    useEffect(() => {
        if (!context.getBuildingInfo || !context.getUser) return;
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        API.getActivities(cancelToken, auth, context.getBuildingInfo.building_id).then(async res => {
            res.data = res.data.filter(e => e.status !== 'deleted')
            for (let activity of res.data) {
                activity.impacts = activity.impacts.filter(e => !e.deleted)
            }
            await GlobalFuncs.getCalculatedValues(context.getBuildingInfo, res.data, cancelToken, auth)
            setLoading(false)
            setActivities(res.data)
            let tempExcel = res.data.map(e => ({
                'Action': e.name,
                'Start': e.startDate,
                'End': e.endDate,
                'Electricity (kWh)': e.kwhToDate,
                'Electricity (kVA)': e.kvaToDate,
                'Water': e.waterToDate,
                'Gas': e.gasToDate,
                'Savings': e.savings,
                'Status': e.status,
                'Emissions': e.coToDate,
            }))
            setExcelList(tempExcel)
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getBuildingInfo, context.getUser])

    useEffect(() => {
        let tempActivities = JSON.parse(JSON.stringify(activities))
        for (let field in filters) {
            if (filters[field] === null) continue
            if (field === 'startDate') {
                tempActivities = tempActivities.filter(activity => new Date(activity[field] + ' 00:00:00.000') >= new Date(filters[field]))
            } else if (field === 'endDate') {
                tempActivities = tempActivities.filter(activity => new Date(activity[field] + ' 00:00:00.000') <= new Date(filters[field]))
            } else {
                tempActivities = tempActivities.filter(activity => activity[field] === filters[field])
            }
        }
        for (let field in sorting) {
            tempActivities.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null && b[field] !== null) return sorting[field] ? 1 : -1;
                if (b[field] === null && a[field] !== null) return sorting[field] ? -1 : 1;
                let afield
                let bfield
                try {
                    afield = new Date(a[field])
                    bfield = new Date(b[field])
                } catch (e) {
                    afield = a[field]
                    bfield = b[field]
                }
                return sorting[field] ? afield - bfield : bfield - afield
            })
        }
        setFilteredActivities(tempActivities)
    }, [activities, filters, sorting])

    useEffect(() => {
        let tempActivitySummary = {
            'Implemented': 0,
            'In Progress': 0,
            'Investigating': 0,
            'On Hold': 0,
            'Not Implemented': 0,
            'Total': 0,
            kwhToDate: 0,
            kwhPriceToDate: 0,
            kwhYear: 0,
            kwhPriceYear: 0,
            kvaToDate: 0,
            kvaPriceToDate: 0,
            kvaYear: 0,
            kvaPriceYear: 0,
            gasToDate: 0,
            gasPriceToDate: 0,
            gasYear: 0,
            gasPriceYear: 0,
            waterToDate: 0,
            waterPriceToDate: 0,
            waterYear: 0,
            waterPriceYear: 0,
            coToDate: 0,
            coPriceToDate: 0,
            coYear: 0,
            coPriceYear: 0,
        }
        for (let activity of filteredActivities) {
            tempActivitySummary[activity.status] += 1
            if (activity.status !== 'Implemented') continue
            for (let key in activity) {
                if (tempActivitySummary.hasOwnProperty(key)) {
                    tempActivitySummary[key] += activity[key]
                }
            }

        }
        tempActivitySummary['Total'] = filteredActivities.length
        for (let key in tempActivitySummary) {
            if (typeof tempActivitySummary[key] === 'number' && !Number.isInteger(tempActivitySummary[key])) {
                tempActivitySummary[key] = Math.round(tempActivitySummary[key])
            }
        }

        setActivitySummary(tempActivitySummary)
    }, [filteredActivities])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);


    return (
        <div className=''>
            <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/conservation?building=all`} className='active-reports-tab'>Building View</Link>
                    <Link to={`/conservation/portfolio`}>Portfolio View</Link>
                </div>
                <div className="relative flex flex-nowrap justify-center items-center w-3/12">
                    <div onClick={() => setShowBuildings(!showBuildings)} className='p-1 border border-2 rounded-[8px] transparent  border-[#E5E7EB] h-[43px] cursor-pointer flex flex-nowrap justify-between items-center w-10/12'>
                        <p className='font-sans font-normal text-sans text-[#C1C1C1]'>{context.getBuilding ? context.getBuilding : "Select building"}</p>
                        {showBuildings && GlobalSVG.sortArrowDesc('#C1C1C1')}
                        {!showBuildings && GlobalSVG.sortArrowAsc('#C1C1C1')}
                    </div>
                    <div className={`${showBuildings ? 'block' : 'hidden'} z-10 h-[300px] absolute laptop:top-[110%] left-0 tablet:top-[100%] mobile:top-[100%] w-full flex items-start justify-center `}>
                        <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-2 right-0'>
                            <Accordion onSelect={() => null} compact={true} linkTo='conservation' />
                        </div>
                    </div>
                </div>
            </div>
            {context.getBuilding ?
                <div className='tablet:pl-12 tablet:pr-6'>
                    <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[30px]'>
                        <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Conservation Summary</h2>
                        <div className="flex flex-nowrap justify-end items-center">
                            {GlobalSVG.screen()}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => context.getBuildingInfo ? setShowLinks(true) : null}>Public-Facing Screens</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        {
                            loading ?
                                <div className='w-full h-[300px] flex flex-nowrap justify-center items-center'>
                                    <Loader />
                                </div> :
                                <div className='overflow-auto h-[305px]'>
                                    <div className="flex flex-nowrap justify-start items-center">
                                        <div className='mr-[15px] min-w-[190px] w-[190px] h-[300px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] pt-[30px] mb-[10px]">
                                                <p className='font-semibold '>Activity Breakdown</p>
                                                <Tooltip
                                                    text="<ul> <li>Implemented: Activity has been successfully implemented.</li>
                                                            <li>In Progress: Activity is currently being implemented.</li>
                                                            <li>Investigating: Activity has been identified and is under review.</li>
                                                            <li>On Hold: Activity may be implemented later or is temporarily paused (e.g., seasonal activities).</li>
                                                            <li>Not Implemented: Activity was not implemented and is not expected to be implemented.</li></ul>"
                                                    right="0"
                                                    left="400%"
                                                    top="0"
                                                    width="520px"
                                                    height="100px"
                                                    textAlign="left"
                                                    show={true}>
                                                    <div className="ml-2">
                                                        {GlobalSVG.info('black', '15', '15')}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                <p className='text-[16px] text-[#107048 mr-2'>{activitySummary['Implemented']}</p>
                                                <p className='text-[12px] text-[#107048]'>Implemented</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                <p className='text-[16px] text-[#F9941E] mr-2'>{activitySummary['In Progress']}</p>
                                                <p className='text-[12px] text-[#F9941E]'>In Progress</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                <p className='text-[16px] text-[#B8BBB9] mr-2'>{activitySummary['Investigating']}</p>
                                                <p className='text-[12px] text-[#B8BBB9]'>Investigating</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                <p className='text-[16px] text-[#000000] mr-2'>{activitySummary['On Hold']}</p>
                                                <p className='text-[12px] text-[#000000]'>On Hold</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                <p className='text-[16px] text-[#F75D5F] mr-2'>{activitySummary['Not Implemented']}</p>
                                                <p className='text-[12px] text-[#F75D5F]'>Not Implemented</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-4 bg-[#F2F8F5]">
                                                <p className='text-[16px] text-[#000000] mr-2'>{activitySummary['Total']}</p>
                                                <p className='text-[12px] text-[#000000]'>Total</p>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[320px] w-[320px] h-[300px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.lightning()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>Electricity Reductions</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2'>Since Implemented</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2 w-[160px] border-r border-[#B8BBB9]'>
                                                        <p className='font-semibold mb-1'>{activitySummary.kwhToDate.toLocaleString()} kWh</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.kwhPriceToDate.toLocaleString()}</p>
                                                    </div>

                                                    <div>
                                                        <Tooltip text="Summation of total monthly peak kVA reduced" show={true}>
                                                            <p className='font-semibold mb-1'>{activitySummary.kvaToDate.toLocaleString()} kVA <span className='text-[10px] align-super'>&#9432;</span></p>
                                                        </Tooltip>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.kvaPriceToDate.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2 mt-4'>Last 12 Months</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2 w-[160px] border-r border-[#B8BBB9]'>
                                                        <p className='font-semibold mb-1'>{activitySummary.kwhYear.toLocaleString()} kWh</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.kwhPriceYear.toLocaleString()}</p>
                                                    </div>
                                                    <div>
                                                        <Tooltip text="Summation of total monthly peak kVA reduced" show={true}>
                                                            <p className='font-semibold mb-1'>{activitySummary.kvaYear.toLocaleString()} kVA <span className='text-[10px] align-super'>&#9432;</span></p>
                                                        </Tooltip>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.kvaPriceYear.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[190px] w-[190px] h-[300px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.fire()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>Gas Reductions</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2'>Since Implemented</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.gasToDate.toLocaleString()} ft³</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.gasPriceToDate.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2 mt-4'>Last 12 Months</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.gasYear.toLocaleString()} ft³</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.gasPriceYear.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[190px] w-[190px] h-[300px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.water2()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>Water Reductions</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2'>Since Implemented</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.waterToDate.toLocaleString()} L</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.waterPriceToDate.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                                <p className='text-[12px] text-[#B8BBB9] font-semibold mb-2 mt-4'>Last 12 Months</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.waterYear.toLocaleString()} L</p>
                                                        <p className='font-semibold text-[#107048]'>${activitySummary.waterPriceYear.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[190px] w-[190px] h-[300px] bg-[#F2F8F5] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.trophy()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>Total Savings</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <p className='text-[12px] text-[##107048] font-semibold mb-2'>Since Implemented</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.coToDate.toLocaleString()} t CO₂e</p>
                                                        <p className='font-semibold text-[#107048]'>${(
                                                            activitySummary.kwhPriceToDate +
                                                            activitySummary.kvaPriceToDate +
                                                            activitySummary.gasPriceToDate +
                                                            activitySummary.waterPriceToDate).toLocaleString()}</p>
                                                    </div>
                                                </div>
                                                <p className='text-[12px] text-[##107048] font-semibold mb-2 mt-4'>Last 12 Months</p>
                                                <div className="flex flex-nowrap justify-between items-center">
                                                    <div className='mb-2'>
                                                        <p className='font-semibold mb-1'>{activitySummary.coYear.toLocaleString()} t CO₂e</p>
                                                        <p className='font-semibold text-[#107048]'>${(
                                                            activitySummary.kwhPriceYear +
                                                            activitySummary.kvaPriceYear +
                                                            activitySummary.gasPriceYear +
                                                            activitySummary.waterPriceYear).toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[30px]'>
                        <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Conservation Activities</h2>
                        <div className="flex flex-nowrap justify-end items-center">
                            <div className={`flex flex-nowrap justify-end items-center tablet:items-end gap-2.5 rounded-md`}>
                               {excelList && <div onClick={() => GlobalFuncs.downloadExcel(excelList, context.getBuildingInfo.building)} className="w-full flex flex-nowrap items-center justify-center">
                                    {GlobalSVG.download()}
                                    <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2 mr-6'>Download</p>
                                </div>}
                                <div className={`relative flex laptop:justify-end laptop:items-start tablet:items-end flex-col multi-select mb-[1px] mr-3`}>
                                    <div className="relative flex items-center justify-start text-sm text-normal text-[grey] cursor-pointer">
                                        <div onClick={() => setShowFilters(!showFilters)} className="w-full ml-2 flex flex-nowrap items-center justify-center">
                                            {GlobalSVG.filter()}
                                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2 mr-6'>Filter</p>
                                        </div>
                                        {
                                            showFilters &&
                                            <div className={`absolute top-[120%] right-[30px] w-[120px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, startDate: null }))} >
                                                    Start Date
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, endDate: null }))} >
                                                    End Date
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, status: null }))} >
                                                    Status
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {GlobalSVG.plus2()}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setShowCreate(true)}>Add Activity</p>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='w-full h-[90px] flex flex-nowrap justify-center items-center'>
                                <Loader />
                            </div> :
                            <ConservationActivities
                                sorting={sorting}
                                setSorting={setSorting}
                                setActivities={setActivities}
                                filteredActivities={filteredActivities}
                                filters={filters}
                                setFilters={setFilters}
                                setShowDetails={setShowDetails} />
                    }
                    {
                        showLinks &&
                        <LinksModal setShowLinks={setShowLinks} />
                    }
                    {
                        (showDetails || showCreate) &&
                        <DetailsModal
                            setShowDetails={setShowDetails}
                            setActivities={setActivities}
                            setShowCreate={setShowCreate}
                            activities={activities}
                            activity={showDetails}
                            create={showCreate ? true : false} />
                    }
                </div>
                :
                <div className='h-[70vh]'>
                    <p className='p-8 text-center'>Please select a building to begin.</p>
                </div>
            }
        </div>
    )

};

export default Conservation;